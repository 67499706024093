.previewBg {
  background-image: url(../../../assets//background/bg-shmn.jpg);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease, height 0.5s ease;
}

.topLayer {
  z-index: 999;
  /* transform: scale(1); */
}
