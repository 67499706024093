.certWrapper {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.overlayGif {
  position: absolute;
  top: 50%;
  right: 8.9%;
  top: 52.8%;
  width: 4%;
}

.overlayMapLink {
  position: absolute;
  top: 50%;
  right: 3%;
  top: 54%;
  display: block;
  width: 17%;
  height: 9%;
}

.animateUp {
  animation: moveToCenter 0.7s ease-in-out forwards;
  z-index: 901;
}

.animateDown {
  animation: moveToBottom 0.7s ease-in-out forwards;
  z-index: 901;
}

.initial {
  top: 100vh;
}

.certImg {
  pointer-events: none;
  border-radius: 10px;
  width: 85vw;
}

@keyframes moveToCenter {
  from {
    top: 100vh;
  }

  to {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes moveToBottom {
  from {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  to {
    top: 100vh;
  }
}

.bgDiv {
  z-index: 900;
  background-color: #0000005d;
  transition: all 1.5s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.fullName {
  position: absolute;
  top: 34%;
  left: 20.5%;
  background-color: #f7f7f7;
  color: #000;
  width: 280px;
  height: 28px;
  font-weight: 700;
  font-size: 22px;
}

.treeLocation {
  position: absolute;
  top: 59%;
  left: 23.5%;
  background-color: #f7f7f7;
  color: #000;
  width: 220px;
  height: 13px;
  font-size: 12px;
}

.locationIcon {
  position: absolute;
  top: 54%;
  left: 82%;
}
