@font-face {
  font-family: 'SFUIText-Regular';
  font-style: bold;
  font-weight: normal;
  font-display: swap;
  src: local('SFUIText-Regular'), url('../../public/fonts/SFUIText-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'SFUIText-Medium';
  font-style: bold;
  font-weight: normal;
  font-display: swap;
  src: local('SFUIText-Medium'), url('../../public/fonts/SFUIText-Medium.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'SFUIText-Bold';
  font-style: bold;
  font-weight: normal;
  font-display: swap;
  src: local('SFUIText-Bold'), url('../../public/fonts/SFUIText-Bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat-Regular';
  font-style: bold;
  font-weight: 400;
  font-display: swap;
  src: local('Montserrat-Medium'),
    url('../../public/fonts/Montserrat-Regular.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserratarm-Regular';
  font-style: bold;
  font-weight: 400;
  font-display: swap;
  src: local('Montserratarm-Regular'),
    url('../../public/fonts/Montserratarm-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserratarm-Bold';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: local('Montserratarm-Bold'),
    url('../../public/fonts/Montserratarm-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserratarm-ExtraBold';
  font-style: bold;
  font-weight: 800;
  font-display: swap;
  src: local('Montserratarm-ExtraBold'),
    url('../../public/fonts/Montserratarm-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat-Medium';
  font-style: bold;
  font-weight: 500;
  font-display: swap;
  src: local('Montserrat-Medium'),
    url('../../public/fonts/Montserrat-Medium.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: local('Montserrat-Bold'), url('../../public/fonts/Montserrat-Bold.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat-Black';
  font-style: bold;
  font-weight: 900;
  font-display: swap;
  src: local('Montserrat-Black'), url('../../public/fonts/Montserrat-Black.ttf') format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
    U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../public/fonts/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../public/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../public/fonts/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url('../../public/fonts/OpenSans-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
