.videoContainer {
  width: 40%;
  aspect-ratio: 1/1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 0.5s ease;
}

.videoContainer video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  min-width: 25%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expanded .playButton {
  display: none;
}

.expanded {
  width: 100%;
  background-color: black;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expanded video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 50%;
  object-fit: cover;
}

.goBack {
  display: inline-block;
  position: absolute;
  top: 50px;
  left: 15px;
  margin-bottom: auto;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .videoContainer {
    width: 30%;
  }

  .expanded {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .videoContainer {
    width: 60%;
  }

  .expanded {
    width: 100%;
  }
}

.spinnerBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
