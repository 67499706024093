* {
  user-select: none;
}

body {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  height: 100%;
}

div#root {
  height: 100%;
}

div.app {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.eWpLko {
  position: absolute !important;
}
/*.jWtbUI {*/
/*  top:75% !important;*/
/*}*/
/* ---- reset ---- */
body {
  margin: 0;
  font: normal 75% Arial, Helvetica, sans-serif;
}
canvas {
  display: block;
  vertical-align: bottom;
} /* ---- particles.js container ---- */
#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* background-image: url(''); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
} /* ---- stats.js ---- */
.count-particles {
  background: #000022;
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  color: #13e8e9;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.js-count-particles {
  font-size: 1.1em;
}
#stats,
.count-particles {
  -webkit-user-select: none;
  user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}
#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.count-particles {
  border-radius: 0 0 3px 3px;
}
.Collapsible {
  width: 140px;
  align-self: center;
  /* display: flex; */
  justify-content: center;
  flex-direction: row;
  text-align: center;
  background: #1976d2;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 20px;
}

#video {
  position: fixed;
  right: 1px;
  bottom: 1px;
  width: 1px;
  height: 1px;
}

#home-preview {
  position: absolute;
  width: 130%;
  height: 100%;
}

#backdrop {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.56) 0%, #000000 100%);
  z-index: 2;
}

#success-img {
  animation-name: successImgAnimation;
  animation-duration: 1s;
}

@keyframes successImgAnimation {
  from {
    scale: 0;
  }
  to {
    scale: 1;
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceInRotated {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: rotate(-45deg) scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: rotate(-45deg) scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: rotate(-45deg) scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: rotate(-45deg) scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: rotate(-45deg) scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: rotate(-45deg) scale3d(1, 1, 1);
  }
}

@keyframes bounce-static {
  0% {
    transform: translateY(-10px);
  }
  20% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(0px);
  }
  80% {
    transform: translateY(-2px);
  }
  90% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0px);
  }
}

.bounce-static {
  animation: bounce-static 850ms ease-out alternate 1;
}

.bounce-in {
  animation-duration: 800ms;
  animation-name: bounceIn;
}

.bounce-in.rotated {
  animation-name: bounceInRotated;
}

.success-box {
  box-sizing: border-box;
  position: absolute;
  width: 368px;
  height: 234px;
  margin-left: auto;
  margin-right: auto;
  bottom: 13px;
  background: linear-gradient(180deg, #000000 39.58%, rgba(0, 0, 0, 0.42) 100%);
  border-radius: 16px;
  z-index: 10;
  color: white;
  text-align: center;
  padding: 10px 15px 24px;
}

.success-box img {
  width: 89px;
  height: 89px;
}

.fade-in {
  animation: fade-in 3s;
}

.fade-out {
  animation: fade-out 3s;
  opacity: 0;
}

.fade-in-out {
  animation: fade-in-out 6s infinite alternate;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-in-out {
  0% {
    opacity: 0.8;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
.logo-container {
  position: relative;
  width: 282px;
  height: 282px;
  display: flex;
  justify-content: center;
}

@media (max-height: 780px) {
  .logo-container {
    top: -85px;
  }
}

/* .logo-container svg {
  transform: rotate(-45deg);
} */

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoom-in {
  animation: zoomIn 1s;
}

.attachment-backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 11;
}

#soulme-logo-wrapper {
  top: -15px;
}

.footer {
  position: absolute;
  bottom: 0px;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border-radius: 10px 10px 0 0;
  
}